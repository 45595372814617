import { memo } from 'react'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'
import SymbolHoldingProfitList from '~/modules/investment-consultant/symbolList/list/SymbolHoldingProfitList'
import { useIntradayPick } from '~/modules/screener/useDatePick'
import { useVirtualAccountHolding } from '~/modules/virtualExchange/useVirtualExchangeResource'
import { store } from '~/pages/heineken_template/_private/store'
import dayAPI from '~/utils/dayAPI'
import { ok1788Store1 } from '../ok1788Stock_SidePane1'
import styleds from '../styleds'

export const HoldingSymbolList = memo<ReactProps>(function HoldingSymbolList() {
  const intraDay = useIntradayPick()
  const userStartDate = dayAPI(intraDay).subtract(80, 'days').format('YYYY-MM-DD')
  const holding = useVirtualAccountHolding('ok1788')?.filter(
    s => s.lastModifiedDatetime > userStartDate,
  )

  const holdingLong =
    holding
      ?.filter(s => s.qty > 0 && s.marketPrice / s.averagePrice > 0.99)
      .sort((a, b) => b.marketPrice / b.averagePrice - a.marketPrice / a.averagePrice) ?? []

  const holdingShort =
    holding
      ?.filter(s => s.qty < 0 && s.averagePrice / s.marketPrice > 0.99)
      .sort((a, b) => b.averagePrice / b.marketPrice - a.averagePrice / a.marketPrice) ?? []

  const longLength = holdingLong.length
  const shortLength = holdingShort.length

  ok1788Store1.longLine2 = longLength
  ok1788Store1.shortLine2 = shortLength

  return (
    <div css={fill_vertical_cross_center}>
      <styleds.SymbolLsitTitle fill='#aa0000'>
        多方持有商品 數量:{longLength}
      </styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SymbolHoldingProfitList.DisplayStyled1
          data={holdingLong ?? []}
          chart={store.charting}
        />
      </styleds.SymbolLsitContent>
      <styleds.SymbolLsitTitle fill='#00aa00'>
        空方持有商品 數量:{shortLength}
      </styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SymbolHoldingProfitList.DisplayStyled1
          data={holdingShort ?? []}
          chart={store.charting}
        />
      </styleds.SymbolLsitContent>
    </div>
  )
})
