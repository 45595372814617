import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useCallback } from 'react'
import { useSnapshot } from 'valtio'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import {
  SignalrSortKey,
  useSortSignalrDataStore,
  useSortVirtualHoldingDataStore,
  VirtualHoldingSortKey,
} from '~/modules/symbolQuote/simple/useSortStore'

const SymbolHoldingProfitHeader1 = memo<ReactProps>(function SymbolHoldingProfitHeader1() {
  const theme = useThemeStore(s => s.theme)

  const sortSnap = useSnapshot(useSortVirtualHoldingDataStore)
  const toggleOrderKey = sortSnap.orderKey === 'desc' ? 'asc' : 'desc'
  const toggleSortKey = sortSnap.sortKey

  const getArrow = useCallback(
    (sortKey: VirtualHoldingSortKey) => {
      return sortKey === sortSnap.sortKey ? (toggleOrderKey === 'desc' ? '▲' : '▼') : undefined
    },
    [sortSnap.sortKey, toggleOrderKey],
  )

  const handleColumnSelected = useCallback(
    (sortKey: VirtualHoldingSortKey) => {
      useSortVirtualHoldingDataStore.sortKey = sortKey
      useSortVirtualHoldingDataStore.orderKey = toggleOrderKey
    },
    [toggleOrderKey],
  )

  return (
    <classes.constainer className={theme}>
      <classes.item
        column='1'
        onClick={() => {
          useSortVirtualHoldingDataStore.sortKey = 'symbol'
          useSortVirtualHoldingDataStore.orderKey = toggleOrderKey
        }}
      >
        {getArrow('symbol')}商品
      </classes.item>
      <classes.item
        column='2'
        onClick={() => {
          handleColumnSelected('marketPrice')
        }}
      >
        {getArrow('marketPrice')}成交價
      </classes.item>
      <classes.item
        column='3'
        onClick={() => {
          handleColumnSelected('averagePrice')
        }}
      >
        {getArrow('averagePrice')}成本
      </classes.item>
      <classes.item
        column='4'
        onClick={() => {
          handleColumnSelected('profitPercentage')
        }}
      >
        {getArrow('profitPercentage')}報酬率
      </classes.item>
      <classes.item
        column='5'
        onClick={() => {
          handleColumnSelected('lastModifiedDatetime')
        }}
      >
        {getArrow('lastModifiedDatetime')}精選日
      </classes.item>
    </classes.constainer>
  )
})

const SymbolHoldingProfitHeader2 = memo<ReactProps>(function SymbolHoldingProfitHeader2() {
  const theme = useThemeStore(s => s.theme)

  const sortSnap = useSnapshot(useSortVirtualHoldingDataStore)
  const toggleOrderKey = sortSnap.orderKey === 'desc' ? 'asc' : 'desc'
  const toggleSortKey = sortSnap.sortKey

  const getArrow = useCallback(
    (sortKey: VirtualHoldingSortKey) => {
      return sortKey === sortSnap.sortKey ? (toggleOrderKey === 'desc' ? '▲' : '▼') : undefined
    },
    [sortSnap.sortKey, toggleOrderKey],
  )

  const handleColumnSelected = useCallback(
    (sortKey: VirtualHoldingSortKey) => {
      useSortVirtualHoldingDataStore.sortKey = sortKey
      useSortVirtualHoldingDataStore.orderKey = toggleOrderKey
    },
    [toggleOrderKey],
  )

  return (
    <classes.constainer className={theme}>
      <classes.item
        column='1'
        onClick={() => {
          useSortVirtualHoldingDataStore.sortKey = 'symbol'
          useSortVirtualHoldingDataStore.orderKey = toggleOrderKey
        }}
      >
        {getArrow('symbol')}商品
      </classes.item>
      <classes.item
        column='2'
        onClick={() => {
          handleColumnSelected('marketPrice')
        }}
      >
        {getArrow('marketPrice')}成交價
      </classes.item>
      <classes.item
        column='3'
        onClick={() => {
          handleColumnSelected('changePrecent')
        }}
      >
        {getArrow('changePrecent')}漲幅
      </classes.item>
      <classes.item
        column='4'
        onClick={() => {
          handleColumnSelected('profitPercentage')
        }}
      >
        {getArrow('profitPercentage')}報酬率
      </classes.item>
      <classes.item
        column='5'
        onClick={() => {
          handleColumnSelected('lastModifiedDatetime')
        }}
      >
        {getArrow('lastModifiedDatetime')}精選日
      </classes.item>
    </classes.constainer>
  )
})

const classes = {
  constainer: styled.div`
    ${fill_horizontal_all_center};
    height: 40px;
    display: grid;
    grid-template-columns: 22% 19% 17% 21% 21%;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
    &.dark {
      background-color: #454545;
      color: #eeeeee;
    }
    &.light {
      background-color: #eeeeee;
      color: #333333;
    }
  `,
  item: styled.div<{ column: string }>`
    ${fill_horizontal_all_center};
    grid-column: ${option => option.column};
  `,
}

export default {
  /** 商品 成交價 成本 報酬率 日期 */
  DisplayStyled1: SymbolHoldingProfitHeader1,
  /** 商品 成交價 漲跌幅 報酬率 日期 */
  DisplayStyled2: SymbolHoldingProfitHeader2,
  classes,
}
