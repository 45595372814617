import { css } from '@emotion/react'
import styled from '@emotion/styled'
import _ from 'lodash'
import { memo, useMemo } from 'react'
import { Margin } from 'recharts'
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new'
import { useSnapshot } from 'valtio'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { useStockAboveMA } from '~/modules/screener/containers/useStockScreenerResource'
import { useSymbolHistoryResource } from '~/modules/screener/containers/useSymbolHistoryResource'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import dayAPI from '~/utils/dayAPI'

const categoryChartMargin: Margin = {
  top: 12,
  right: 8,
  left: 8,
  bottom: 8,
}

const CustomizedLegend = ({ payload }: { payload?: any[] }) => {
  return (
    <LegendStyled.Container>
      <div>
        <LegendStyled.LegendItem barFill={classesFill.ma20LineFill} />
        站上月線家數
      </div>
      <div>
        <LegendStyled.LegendItem barFill={classesFill.ma60LineFill} />
        站上季線家數
      </div>
    </LegendStyled.Container>
  )
}

const CustomizedTooltip = ({ payload }: { payload?: any[] }) => {
  if (!payload || (payload && payload.length < 1)) return null
  const datum = payload[0].payload

  const date = datum.date
  const ma20 = datum.ma20
  const ma60 = datum.ma60
  const allStock = datum.allStock
  const ma20Percent = ((ma20 / allStock) * 100).toFixed(1)
  const ma60Percent = ((ma60 / allStock) * 100).toFixed(1)
  const price = datum.price

  return (
    <TooltipStyled.Container>
      <div>{date}</div>
      <div
        css={css`
          color: ${'#222222'};
        `}
      >
        加權指數收盤價: {price}
      </div>
      <div
        css={css`
          color: ${classesFill.ma20LineFill};
        `}
      >
        站上月線家數: {ma20} ({ma20Percent}%)
      </div>
      <div
        css={css`
          color: ${classesFill.ma60LineFill};
        `}
      >
        站上季線家數: {ma60} ({ma60Percent}%)
      </div>
    </TooltipStyled.Container>
  )
}

const StockAboveMaChart = memo<
  ReactProps<{ market1: string; market2: string; days: number; ma1: number; ma2: number }>
>(function StockAboveMaChart(props) {
  const baseDate = useSnapshot(staticStore).tradedDate.day0

  const dataMa20 = useStockAboveMA({
    date: baseDate.format('YYYY/MM/DD'),
    days: props.days,
    ma: props.ma1,
    market: props.market2,
  })

  const dataMa60 = useStockAboveMA({
    date: baseDate.format('YYYY/MM/DD'),
    days: props.days,
    ma: props.ma2,
    market: props.market2,
  })
  const date_ = _.map(dataMa20.data, (value, key) => key)
  const ma20_ = _.map(dataMa20.data, 'count[1]')
  const ma60_ = _.map(dataMa60.data, 'count[1]')
  const allStock = _.map(dataMa60.data, 'count[0]')[0] + _.map(dataMa60.data, 'count[1]')[1]

  const endDate = date_[0]
  const startDate = date_[date_.length - 1]

  const range: [number, number] = [
    dayAPI(startDate).subtract(10, 'day').unix(),
    dayAPI(endDate).unix(),
  ]

  const { data: rawData } = useSymbolHistoryResource({
    symbol: 'TSEA',
    fromTo: range,
    resolution: '1D',
  })

  const indexData = useMemo(() => {
    return rawData?.map(datum => ({
      close: datum.close,
    }))
  }, [rawData])
    ?.map(s => s.close)
    .reverse()

  const data1 = date_
    .map((dateValue, index) => ({
      date: dateValue,
      ma20: ma20_[index],
      ma60: ma60_[index],
      price: indexData?.[index],
      allStock: allStock,
    }))
    .reverse()

  const priceMin = Math.min(..._.map(indexData, 'close'))
  const priceMax = Math.max(..._.map(indexData, 'close'))

  if (!date_ || !ma20_ || !ma60_ || !indexData || !data1)
    return (
      <div
        css={css`
          ${fill_horizontal_all_center}
        `}
      >
        ...尚未載入
      </div>
    )

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <ComposedChart
        data={data1}
        margin={categoryChartMargin}
      >
        <CartesianGrid
          stroke='#f5f5f5'
          strokeDasharray='1'
          verticalPoints={[]}
          horizontalPoints={[0]}
        />
        <XAxis
          dataKey='date'
          scale='point'
          axisLine={true}
          fontSize={14}
          tickFormatter={timeStr => timeStr.slice(5, 10)}
          padding={{ left: 4, right: 4 }}
        />
        <YAxis
          width={40}
          yAxisId={1}
          fontSize={14}
          tickMargin={0}
          tickCount={3}
        />
        <YAxis
          width={40}
          yAxisId={2}
          orientation='right'
          fontSize={14}
          domain={[priceMin, priceMax]}
          tickFormatter={str => str.toFixed(0)}
        />
        <Tooltip content={<CustomizedTooltip />} />
        <Bar
          yAxisId={2}
          dataKey='price'
          type='basis'
          fill={classesFill.priceBarFill}
          fillOpacity={0.5}
          isAnimationActive={false}
        />
        <Line
          yAxisId={1}
          dataKey='ma60'
          stroke={classesFill.ma60LineFill}
          dot={false}
          width={2}
          isAnimationActive={false}
        />
        <Line
          yAxisId={1}
          dataKey='ma20'
          stroke={classesFill.ma20LineFill}
          dot={false}
          width={2}
          isAnimationActive={false}
        />
        <Legend
          width={330}
          content={<CustomizedLegend />}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
})

const classesFill = {
  ma20LineFill: '#2ed0c9',
  ma60LineFill: '#f12591',
  priceBarFill: '#aaaaaa',
}

export default {
  display: StockAboveMaChart,
  classesFill,
}

const TooltipStyled = {
  Container: styled.div`
    width: 148px;
    padding: 4px;
    background-color: #fafafacc;
    opacity: 1;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    font-size: 14px;
    line-height: 24px;
  `,
}

const LegendStyled = {
  Container: styled.div`
    ${fill_horizontal_all_center};
    & > * {
      ${fill_horizontal_all_center};
      width: 40%;
    }
    font-size: 14px;
    line-height: 20px;
    gap: 32px;
  `,
  LegendItem: styled.div<{ barFill: string }>`
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: ${options => options.barFill};
  `,
}
