import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { SignalSymbolArray, useFirebaseValue } from '~/hooks/useFirebaseValue'
import { fill } from '~/modules/AppLayout/FlexGridCss'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import { store } from '~/pages/heineken_template/_private/store'
import styleds from '../styleds'

export const FirebaseSymbolList = memo<ReactProps>(function FirebaseSymbolList() {
  const longFirebase = useFirebaseValue<SignalSymbolArray>('ok1788_signal', 'long')
  const shortFirebase = useFirebaseValue<SignalSymbolArray>('ok1788_signal', 'short')

  return (
    <div css={fill}>
      <styleds.SymbolLsitTitle fill='#aa0000'>
        多方精選 數量:{longFirebase?.symbols?.length}
      </styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart
          data={longFirebase?.symbols ?? []}
          chart={store.charting}
        />
      </styleds.SymbolLsitContent>
      <styleds.SymbolLsitTitle fill='#00aa00'>
        空方精選 數量:{shortFirebase?.symbols?.length}
      </styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart
          data={shortFirebase?.symbols ?? []}
          chart={store.charting}
        />
      </styleds.SymbolLsitContent>
    </div>
  )
})
