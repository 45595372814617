import { memo, useMemo } from 'react'
import { useSnapshot } from 'valtio'
import { fill } from '~/modules/AppLayout/FlexGridCss'
import { useLatestTradeDateSize } from '~/modules/screener/containers/useStockScreenerResource'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import { useVirtualTransaction } from '~/modules/virtualExchange/useVirtualExchangeResource'
import { good178S_store } from '~/pages/good178_stock/_private/good178S_store'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import { store } from '~/pages/heineken_template/_private/store'
import dayAPI from '~/utils/dayAPI'
import { ok1788Store1 } from '../ok1788Stock_SidePane1'
import styleds from '../styleds'

export const EntrySymbolList = memo<ReactProps>(function EntrySymbolList() {
  const state = useSnapshot(ok1788Store1)
  const staticState = useSnapshot(staticStore)

  const beginDate = staticState.tradedDate.day0
    .subtract(state.virtualDate, 'day')
    .format('YYYY-MM-DD')

  const date = staticState.tradedDate.day0.format('YYYY-MM-DD')
  const data = useVirtualTransaction('ok1788', {
    beginDatetime: beginDate,
    endDatetime: date,
  })

  const signalLong = useMemo(() => {
    return data?.filter(f => f.positionType === 'O' && f.bs === 'B')?.map(f => f.symbol) ?? []
  }, [JSON.stringify(data)])
  const signalShort = useMemo(() => {
    return data?.filter(f => f.positionType === 'O' && f.bs === 'S')?.map(f => f.symbol) ?? []
  }, [JSON.stringify(data)])

  const longLength = signalLong.length
  const shortLength = signalShort.length

  ok1788Store1.longLine1 = longLength
  ok1788Store1.shortLine1 = shortLength

  return (
    <div css={fill}>
      <styleds.SymbolLsitTitle fill='#aa0000'>
        近期三道向上 數量:{longLength}
      </styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart
          data={signalLong}
          chart={store.charting}
        />
      </styleds.SymbolLsitContent>
      <styleds.SymbolLsitTitle fill='#00aa00'>
        近期三道向下 數量:{shortLength}
      </styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart
          data={signalShort}
          chart={store.charting}
        />
      </styleds.SymbolLsitContent>
    </div>
  )
})
