import { isString, sortBy } from 'lodash'
import { memo, useEffect, useMemo } from 'react'
import { useSnapshot } from 'valtio'
import { fill } from '~/modules/AppLayout/FlexGridCss'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import { store } from '~/pages/heineken_template/_private/store'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { ok1788Store1 } from '../ok1788Stock_SidePane1'
import styleds from '../styleds'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'

export const RankSymbolList = memo<ReactProps>(function RankSymbolList() {
  const state = useSnapshot(ok1788Store1)

  //股票的分類------
  const tw50 = useSnapshot(staticStore).symbol.tw50
  const otc50 = useSnapshot(staticStore).symbol.otc50
  const stockFutures = useSnapshot(staticStore).symbol.stockFuturesMapper
  const stockCategory = useSnapshot(staticStore).symbol.stockCategory

  //訂閱報價------
  const resultSymbol = useMemo(() => {
    if (state.stockType === 'tw50') return tw50
    else if (state.stockType === 'otc50') return otc50
    else if (state.stockType === 'stockFutures') return Object.keys(stockFutures ?? [])
    else if (state.stockType === 'stockCategory')
      return stockCategory.map(datum => datum.index_symbol)
    else return []
  }, [state.stockType, tw50, otc50, stockFutures, stockCategory])

  const rankingSymbol = resultSymbol

  useEffect(() => {
    useSignalrStore.getState().subscribeAdd([...(rankingSymbol || [])], 'ohlc')
    return () => {
      useSignalrStore.getState().subscribeRemove([...(rankingSymbol || [])], 'ohlc')
    }
  }, [JSON.stringify(rankingSymbol)])

  const quoteArray = useSignalrStoreValueOHLC(state_ =>
    state_.useGroupOHLC(rankingSymbol as string[]),
  )

  /** Ranking 當日漲幅排行 */
  const symbolLong = useMemo(() => {
    return sortBy(quoteArray, datum => ((datum?.close ?? 0) / (datum?.prevRef ?? 0)) * 0.01)
      .filter(datum => (datum?.close ?? 0) > (datum?.open ?? 0))
      ?.map(datum => datum?.symbol)
      .reverse()
      .filter(isString) as Signalr.SymbolString[]
  }, [quoteArray])

  /** Ranking 當日跌幅排行 */
  const symbolShort = useMemo(() => {
    return sortBy(quoteArray, datum => ((datum?.close ?? 0) / (datum?.prevRef ?? 0)) * 0.01)
      .filter(datum => (datum?.close ?? 0) < (datum?.open ?? 0))
      ?.map(datum => datum?.symbol)
      .filter(isString) as Signalr.SymbolString[]
  }, [quoteArray])

  return (
    <div css={fill}>
      <styleds.SymbolLsitTitle fill='#aa0000'>
        今日多方強勢({symbolLong.length})
      </styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart
          data={symbolLong}
          chart={store.charting}
          noAutoSubscribe={true}
        />
      </styleds.SymbolLsitContent>
      <styleds.SymbolLsitTitle fill='#00aa00'>
        今日空方強勢({symbolShort.length})
      </styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart
          data={symbolShort}
          chart={store.charting}
          noAutoSubscribe={true}
        />
      </styleds.SymbolLsitContent>
    </div>
  )
})
