export const indexMacd = (interval: string) => {
  if (interval === '1D') {
    return 21
  } else if (interval === '1W') {
    return 22
  } else if (interval === '1M') {
    return 26
  } else return 21
}
export const indexDmi1 = (interval: string) => {
  if (interval === '1D') {
    return 11
  } else if (interval === '1W') {
    return 7
  } else if (interval === '1M') {
    return 7
  } else return 11
}
export const indexDmi2 = (interval: string) => {
  if (interval === '1D') {
    return 7
  } else if (interval === '1W') {
    return 1
  } else if (interval === '1M') {
    return 1
  } else return 7
}

export const indexKd1 = (interval: string) => {
  if (interval === '1D') {
    return 9
  } else if (interval === '1W') {
    return 4
  } else if (interval === '1M') {
    return 2
  } else return 9
}
export const indexKd2 = (interval: string) => {
  if (interval === '1D') {
    return 3
  } else if (interval === '1W') {
    return 3
  } else if (interval === '1M') {
    return 2
  } else return 3
}
export const indexKd3 = (interval: string) => {
  if (interval === '1D') {
    return 3
  } else if (interval === '1W') {
    return 3
  } else if (interval === '1M') {
    return 2
  } else return 3
}


//stock

export const stockMacd = (interval: string) => {
  if (interval === '1D') {
    return 23
  } else if (interval === '1W') {
    return 5
  } else if (interval === '1M') {
    return 7
  } else return 23
}
export const stockDmi1 = (interval: string) => {
  if (interval === '1D') {
    return 18
  } else if (interval === '1W') {
    return 2
  } else if (interval === '1M') {
    return 2
  } else return 18
}
export const stockDmi2 = (interval: string) => {
  if (interval === '1D') {
    return 1
  } else if (interval === '1W') {
    return 1
  } else if (interval === '1M') {
    return 1
  } else return 1
}

export const stockKd1 = (interval: string) => {
  if (interval === '1D') {
    return 10
  } else if (interval === '1W') {
    return 2
  } else if (interval === '1M') {
    return 2
  } else return 10
}
export const stockKd2 = (interval: string) => {
  if (interval === '1D') {
    return 3
  } else if (interval === '1W') {
    return 3
  } else if (interval === '1M') {
    return 3
  } else return 3
}
export const stockKd3 = (interval: string) => {
  if (interval === '1D') {
    return 3
  } else if (interval === '1W') {
    return 3
  } else if (interval === '1M') {
    return 3
  } else return 3
}
