import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { indexMacd, stockMacd } from '~/pages/ok1788_stock/_private/indicatorsParameter'

//變形macd
export const ok1788_customized1 = createIndicator({
  displayName: 'customized1',
  id: 'ok1788customized1',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const interval = context.symbol.period
      const symbolNumber = context.symbol.ticker
      const isStockSymbol = symbolNumber.match(/^[\d]{4}$/)

      const customized1MACD = isStockSymbol ? stockMacd(interval) : indexMacd(interval)

      const val = this.ok1788customized1(customized1MACD)

      return [val]
    },
  },
  metainfo: {
    is_price_study: !1,
    _metainfoVersion: 42,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    plots: [{ id: 'plot_0', type: 'line' }],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 20,
          visible: !0,
          color: '#603cb0',
        },
      },
      precision: 2,
      // inputs: { in_0: 60 },
    },
    styles: {
      plot_0: {
        title: '柱狀圖',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    inputs: [
      // {
      //   id: 'in_0',
      //   name: 'macd',
      //   defval: 60,
      //   type: 'integer',
      //   min: 1,
      //   max: 240,
      // },
    ],
  },
})
