import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { fill_horizontal_all_center, flex } from '~/modules/AppLayout/FlexGridCss'

const Styleds = {
  SideBar: styled.div`
    ${flex.v.crossCenter};
    width: 100%;
    height: 100%;
    gap: 4px;
    padding: 4px;
  `,
  SubTitle: styled.div`
    ${flex.h.allCenter}
    width: 100%;
    height: 25px;
    background-color: #eeeeee;
  `,
  ChartContent: styled.div`
    width: 100%;
    height: 180px;
  `,
  ButtonGroup: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
    width: 100%;
    gap: 2px;
    font-size: 14px;
  `,
  /** 選股面板 */
  Dashboard: styled.div`
    ${flex.v.allCenter};
    background-color: #eeeeee;
    border-radius: 5px;
    width: 100%;
    gap: 2px;
    padding: 2px;
  `,
  DashboardSelectGroup: styled.div`
    ${flex.h.crossCenter};
    width: 100%;
    height: 48px;
  `,
  DashboardSelectItem: styled.div`
    ${flex.h.allCenter}
    width: 50%;
  `,

  /** 選擇選股類別按鈕 */
  ScreenerButton: styled.div<{
    active?: boolean
  }>`
    ${flex.h.allCenter}
    width: 100%;
    height: 28px;
    color: #111111;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      border: 1px solid #aaaaaa;
    }
    ${options => {
      const dafault = css`
        background-color: #f4f9fb;
        color: #333333;
        border: 1px solid #555555;
      `
      const active =
        options.active &&
        css`
          background: linear-gradient(180deg, #813ec1, #49116b);
          color: #eeeeee;
          border: 1px solid #555555;
        `

      return css([dafault, active])
    }}
  `,
  /** 欄位功能標題 */
  SidebarTitle: styled.div`
    ${flex.h.allCenter};
    width: 100%;
    height: 30px;
    color: #ffffff;
    background-color: #aaaaaa;
    font-size: 16px;
    border-radius: 5px;
    margin: 2.5px 0px;
  `,
  /** Mui select 天數選擇css */
  SelectButtonCss: css`
    ${flex.h.allCenter}
    width: 80px;
    height: 32px;
    font-size: 14px;
    background-color: #ffffff;
  `,
  MainSidebarTilte: styled.div`
    ${flex.h.allCenter};
    width: 100%;
    height: 32px;
    color: #ffffff;
    background-color: #6d34a0;
    font-size: 16px;
    border-radius: 5px;
  `,
  SymbolLsitTitle: styled.div<{ fill: string }>`
    ${flex.h.allCenter}
    height: 28px;
    width: 100%;
    background-color: ${options => options.fill};
    color: #eeeeee;
    border-radius: 5px 5px 0px 0px;
  `,
  SymbolLsitContent: styled.div`
    width: 100%;
    height: calc(50% - 30px);
  `,
  symbolMark: styled.div<{ fill: string }>`
    width: 10px;
    height: 10px;
    background-color: ${options => options.fill};
    border-radius: 50%;
  `,
}

export default Styleds
